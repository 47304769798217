import React, {useCallback} from 'react'
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import Svg, {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {usePalette} from '#/lib/hooks/usePalette'
import {isNative} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {useTgStore} from '#/view/com/util/sdlStore/TgStore'
import {useTwStore} from '#/view/com/util/sdlStore/TwStore'
import {TWTagIcon} from '#/view/icons/FeedIcons'
import {RightIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import * as Prompt from '#/components/Prompt'

export function LinkSocial() {
  const {_} = useLingui()
  const [currentUnlink, setCurrentUnlink] = React.useState('tw')
  const discardPromptControl = Prompt.usePromptControl()

  const {twStore, unlinkTw, isBoundTw} = useTwStore(),
    {tgStore, unlinkTg, bind: bindTelegram} = useTgStore(),
    {openModal} = useModalControls()

  const onPressTg = useCallback(() => {
    if (!tgStore.hasConnected) {
      openModal({name: 'telegram'})
    } else {
      if (3 > 8) {
        discardPromptControl.open()
        setCurrentUnlink('tg')
      }
    }
  }, [discardPromptControl, openModal, tgStore.hasConnected])

  const onPressTw = useCallback(() => {
    if (!twStore.twitterUserName) {
      openModal({name: 'link-social', hideTelegramBt: true})
    } else {
      discardPromptControl.open()
      setCurrentUnlink('tw')
    }
  }, [discardPromptControl, openModal, twStore.twitterUserName])

  const onPressUnlink = useCallback(() => {
    if (currentUnlink === 'tg') {
      unlinkTg()
    } else {
      unlinkTw()
    }
  }, [currentUnlink, unlinkTg, unlinkTw])

  const t = useTheme()
  const pal = usePalette('default')
  return (
    <>
      <Text
        style={{
          color: t.palette.gray_10,
          fontSize: 14,
          fontWeight: 'bold',
          marginTop: 12,
          marginBottom: 10,
        }}>
        {bindTelegram || isBoundTw ? (
          <Trans>Linked social account</Trans>
        ) : (
          <Trans>Link social account</Trans>
        )}
      </Text>
      <View
        style={[
          a.align_center,
          a.justify_center,
          a.rounded_md,
          t.atoms.input_bg,
        ]}>
        {!isNative && (
          <TouchableOpacity
            style={[styles.linkCard, pal.border, {borderBottomWidth: 1}]}
            accessibilityRole="button"
            onPress={onPressTw}
            accessibilityHint={undefined}>
            <TWTagIcon size={34} />
            <Text style={[t.atoms.text, styles.social]}>X (Twitter)</Text>
            <View style={{flex: 1}} />
            {twStore.twitterUserName ? (
              <Text style={[styles.socialName, t.atoms.text_sub]}>
                @{twStore.twitterUserName}
              </Text>
            ) : (
              <Text style={[styles.socialName, t.atoms.text_sub]}>
                <Trans>Link</Trans>
              </Text>
            )}
            <RightIcon />
          </TouchableOpacity>
        )}

        <TouchableOpacity
          style={[styles.linkCard]}
          accessibilityRole="button"
          onPress={onPressTg}
          accessibilityHint={undefined}>
          <Svg width="34" height="35" viewBox="0 0 34 35" fill="none">
            <G clipPath="url(#clip0_7135_353219)">
              <Path
                d="M17 0.559937C12.4923 0.559937 8.16531 2.35211 4.98047 5.53908C1.79236 8.72733 0.000912285 13.0512 0 17.5599C0 22.0668 1.79297 26.3938 4.98047 29.5808C8.16531 32.7678 12.4923 34.5599 17 34.5599C21.5077 34.5599 25.8347 32.7678 29.0195 29.5808C32.207 26.3938 34 22.0668 34 17.5599C34 13.0531 32.207 8.72605 29.0195 5.53908C25.8347 2.35211 21.5077 0.559937 17 0.559937Z"
                fill="url(#paint0_linear_7135_353219)"
              />
              <Path
                d="M7.69519 17.3804C12.6518 15.2214 15.9561 13.798 17.6083 13.1102C22.3311 11.1464 23.3113 10.8054 23.9514 10.7938C24.0922 10.7915 24.4057 10.8263 24.6102 10.9917C24.7802 11.1311 24.828 11.3197 24.8519 11.4522C24.8732 11.5844 24.9024 11.8859 24.8785 12.1213C24.6235 14.8094 23.5158 21.3326 22.9527 24.3435C22.7163 25.6174 22.2461 26.0445 21.7919 26.0862C20.8038 26.1771 20.0547 25.4339 19.0985 24.8072C17.603 23.8263 16.7583 23.2159 15.3053 22.2588C13.6266 21.1528 14.7157 20.5448 15.6719 19.5513C15.9216 19.2913 20.2725 15.3348 20.3549 14.9759C20.3655 14.931 20.3761 14.7637 20.2752 14.6755C20.1769 14.5871 20.0308 14.6173 19.9246 14.6412C19.7732 14.6752 17.3852 16.2552 12.7527 19.3808C12.0753 19.8467 11.4618 20.0738 10.9093 20.0619C10.3036 20.0488 9.13488 19.7187 8.26629 19.4366C7.20379 19.0905 6.35644 18.9075 6.43082 18.3196C6.46801 18.0136 6.89035 17.7005 7.69519 17.3804Z"
                fill="white"
              />
            </G>
            <Defs>
              <LinearGradient
                id="paint0_linear_7135_353219"
                x1="1700"
                y1="0.559937"
                x2="1700"
                y2="3400.56"
                gradientUnits="userSpaceOnUse">
                <Stop stopColor="#2AABEE" />
                <Stop offset="1" stopColor="#229ED9" />
              </LinearGradient>
              <ClipPath id="clip0_7135_353219">
                <Rect
                  width="34"
                  height="34"
                  fill="white"
                  transform="translate(0 0.559937)"
                />
              </ClipPath>
            </Defs>
          </Svg>
          <Text style={[t.atoms.text, styles.social]}>Telegram</Text>
          <View style={{flex: 1}} />
          {tgStore.hasConnected ? (
            <Text style={[styles.socialName, t.atoms.text_sub]}>
              @{tgStore.userName}
            </Text>
          ) : (
            <Text style={[styles.socialName, t.atoms.text_sub]}>
              <Trans>Link</Trans>
            </Text>
          )}
          <RightIcon />
        </TouchableOpacity>
      </View>
      <Prompt.Basic
        control={discardPromptControl}
        title={
          currentUnlink === 'tw'
            ? _(msg`Unlink X(Twitter)?`)
            : _(msg`Unlink Telegram?`)
        }
        description={
          currentUnlink === 'tw'
            ? _(
                msg`After unlinking X account, you will not be able to spill publicly due to relevant laws and regulations.`,
              )
            : _(
                msg`After unlinking Telegram account, you will no longer be able to use Circle`,
              )
        }
        onConfirm={onPressUnlink}
        confirmButtonCta={_(msg`Unlink`)}
        confirmButtonColor="negative"
      />
    </>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    backgroundColor: 'pink',
    paddingVertical: 10,
  },
  linkCard: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 20,
    height: 56,
    // marginBottom: 1,
  },
  social: {fontSize: 14, fontWeight: 'bold', marginLeft: 12},
  socialName: {fontSize: 14, transform: [{translateY: -1}], marginRight: 5},
  line: {
    marginHorizontal: 12,
    marginVertical: 5,
    height: 1,
    width: '100%',
    // backgroundColor: '#e0e0e0',
  },
})
