import React from 'react'
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useFocusEffect, useNavigation} from '@react-navigation/native'

import {isWeb} from '#/platform/detection'
import {useProfileQuery} from '#/state/queries/profile'
import {SessionAccount, useSession, useSessionApi} from '#/state/session'
import {useSetMinimalShellMode, useThemePrefs} from '#/state/shell'
import {useAnalytics} from 'lib/analytics/analytics'
import {appVersion, bundleInfo} from 'lib/app-info'
import {useAccountSwitcher} from 'lib/hooks/useAccountSwitcher'
import {usePalette} from 'lib/hooks/usePalette'
import {makeProfileLink} from 'lib/routes/links'
import {CommonNavigatorParams, NativeStackScreenProps} from 'lib/routes/types'
import {NavigationProp} from 'lib/routes/types'
import {colors, s} from 'lib/styles'
import {RightIcon} from '#/view/icons/ModalIcons'
import {
  InfoCircleIcon,
  LanguageIcon,
  TextSizeIcon,
  ThemeIcon,
} from '#/view/icons/SettingIcons'
import {useHideBottomBar} from '#/view/shell/bottom-bar/BottomBar'
import {AccountDropdownBtn} from 'view/com/util/AccountDropdownBtn'
import {Link} from 'view/com/util/Link'
import {Text} from 'view/com/util/text/Text'
import {UserAvatar} from 'view/com/util/UserAvatar'
import {ViewHeader} from 'view/com/util/ViewHeader'
import {CenteredView} from 'view/com/util/Views'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {useDialogControl} from '#/components/Dialog'
import {ExportCarDialog} from './ExportCarDialog'
import {LinkSocial} from './LinkSocial'

function SettingsAccountCard({
  account,
  pendingDid,
  onPressSwitchAccount,
  style,
}: {
  account: SessionAccount
  pendingDid: string | null
  onPressSwitchAccount: (
    account: SessionAccount,
    logContext: 'Settings',
  ) => void
  style?: StyleProp<ViewStyle>
}) {
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const {currentAccount} = useSession()
  const {data: profile} = useProfileQuery({did: account.did})
  const isCurrentAccount = account.did === currentAccount?.did

  const contents = (
    <View
      style={[
        t.atoms.input_bg,
        styles.linkCard,
        {borderTopRightRadius: 8, borderTopLeftRadius: 8},
        account.did === pendingDid && t.atoms.bg_contrast_25,
        style,
      ]}>
      <View style={styles.avi}>
        <UserAvatar
          size={40}
          avatar={profile?.avatar}
          type={profile?.associated?.labeler ? 'labeler' : 'user'}
        />
      </View>
      <View style={[s.flex1]}>
        <Text type="md-bold" style={[pal.text, a.self_start]} numberOfLines={1}>
          {profile?.displayName || formatHandler(account.handle)}
        </Text>
        <Text type="sm" style={pal.textLight} numberOfLines={1}>
          {formatHandler(account.handle)}
        </Text>
      </View>
      <AccountDropdownBtn account={account} />
    </View>
  )

  return isCurrentAccount ? (
    <Link
      href={makeProfileLink({
        did: currentAccount?.did,
        handle: currentAccount?.handle,
      })}
      title={_(msg`Your profile`)}
      noFeedback>
      {contents}
    </Link>
  ) : (
    <TouchableOpacity
      testID={`switchToAccountBtn-${account.handle}`}
      key={account.did}
      onPress={
        pendingDid ? undefined : () => onPressSwitchAccount(account, 'Settings')
      }
      accessibilityRole="button"
      accessibilityLabel={_(msg`Switch to ${formatHandler(account.handle)}`)}
      accessibilityHint={_(msg`Switches the account you are logged in to`)}
      activeOpacity={0.8}>
      {contents}
    </TouchableOpacity>
  )
}

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Settings'>
export function SettingsScreen({}: Props) {
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const setMinimalShellMode = useSetMinimalShellMode()
  const navigation = useNavigation<NavigationProp>()
  const {screen} = useAnalytics()
  const {accounts, currentAccount} = useSession()
  const {logoutEveryAccount} = useSessionApi()
  const exportCarControl = useDialogControl()
  const {pendingDid, onPressSwitchAccount} = useAccountSwitcher()
  const isSwitchingAccounts = !!pendingDid
  const {colorMode} = useThemePrefs()
  useHideBottomBar()

  useFocusEffect(
    React.useCallback(() => {
      screen('Settings')
      setMinimalShellMode(false)
    }, [screen, setMinimalShellMode]),
  )

  const onPressLanguageSettings = React.useCallback(() => {
    navigation.navigate('LanguageSettings')
  }, [navigation])

  const onPressLogoutEveryAccount = React.useCallback(() => {
    window.localStorage.clear()
    if (isWeb) {
      location.href = '/'
    } else {
      navigation.goBack()
    }
    logoutEveryAccount('Settings')
  }, [logoutEveryAccount, navigation])

  const onPressAppearanceSettings = React.useCallback(() => {
    navigation.navigate('AppearanceSettings')
  }, [navigation])

  const onPressTextSizeSettings = React.useCallback(() => {
    navigation.navigate('TextSizeSettings')
  }, [navigation])

  const otherLength = accounts
    .filter(a => a.did !== currentAccount?.did)
    .filter(a => a.accessJwt && a.mnemonic)?.length

  return (
    <CenteredView
      style={[s.hContentRegion, t.atoms.bg_gray, {borderWidth: 0}]}
      testID="settingsScreen">
      <ExportCarDialog control={exportCarControl} />
      {/* <BirthDateSettingsDialog control={birthdayControl} /> */}

      <ViewHeader
        canGoBack
        showOnDesktop
        showHorizontalBorder
        title={_(msg`Settings`)}
        style={t.atoms.bg_gray}
      />
      <ScrollView style={[t.atoms.bg_gray, {height: 1}]}>
        {currentAccount ? (
          <>
            <View
              style={[a.pl_2xl, a.pr_2xl]}
              pointerEvents={pendingDid ? 'none' : 'auto'}>
              <Text
                style={[
                  a.text_sm,
                  a.font_semibold,
                  a.my_md,
                  {color: t.palette.gray_10},
                ]}>
                Signed in as
              </Text>
              <SettingsAccountCard
                account={currentAccount}
                onPressSwitchAccount={onPressSwitchAccount}
                pendingDid={pendingDid}
                style={
                  otherLength <= 0
                    ? {borderRadius: 8}
                    : {borderTopRightRadius: 8, borderTopLeftRadius: 8}
                }
              />
            </View>
          </>
        ) : null}

        <View
          style={[a.pl_2xl, a.pr_2xl]}
          pointerEvents={pendingDid ? 'none' : 'auto'}>
          {accounts
            .filter(a => a.did !== currentAccount?.did)
            .filter(a => a.accessJwt && a.mnemonic)
            .map((account, index) => (
              <SettingsAccountCard
                key={account.did}
                account={account}
                onPressSwitchAccount={onPressSwitchAccount}
                pendingDid={pendingDid}
                style={[
                  pal.border,
                  index === otherLength - 1
                    ? {
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderTopWidth: 1,
                      }
                    : {},
                ]}
              />
            ))}

          <LinkSocial />

          <Text
            style={[
              a.text_sm,
              a.font_semibold,
              a.my_md,
              {color: t.palette.gray_10},
            ]}>
            Basics
          </Text>
          {/* Theme */}
          {false && (
            <TouchableOpacity
              testID="appearanceSettingsBtn"
              style={[
                styles.linkCard,
                t.atoms.input_bg,
                isSwitchingAccounts && styles.dimmed,
                {borderTopRightRadius: 8, borderTopLeftRadius: 8},
                pal.border,
                {borderBottomWidth: 1},
              ]}
              onPress={
                isSwitchingAccounts ? undefined : onPressAppearanceSettings
              }
              accessibilityRole="button"
              accessibilityLabel={_(msg`Appearance settings`)}
              accessibilityHint={_(msg`Opens appearance settings`)}>
              <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                <View style={[styles.iconContainer, t.atoms.bg_btn_default]}>
                  <ThemeIcon />
                </View>
                <Text type="lg" style={pal.text}>
                  <Trans>Theme</Trans>
                </Text>
              </View>
              <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                <Text
                  style={[
                    a.text_sm,
                    t.atoms.text_sub,
                    {textTransform: 'capitalize'},
                  ]}>
                  {colorMode} Mode
                </Text>
                {/* <Chevron size="sm" style={[pal.text]} /> */}
                <RightIcon />
              </View>
            </TouchableOpacity>
          )}

          {/* Text Size */}
          <TouchableOpacity
            testID="appearanceSettingsBtn"
            style={[
              styles.linkCard,
              t.atoms.input_bg,
              pal.border,
              {borderBottomWidth: 1},
            ]}
            onPress={isSwitchingAccounts ? undefined : onPressTextSizeSettings}
            accessibilityRole="button"
            accessibilityLabel={_(msg`Text Size settings`)}
            accessibilityHint={_(msg`Opens text size settings`)}>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <View style={[styles.iconContainer, t.atoms.bg_btn_default]}>
                <TextSizeIcon />
              </View>
              <Text type="lg" style={pal.text}>
                <Trans>Text Size</Trans>
              </Text>
            </View>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <RightIcon />
            </View>
          </TouchableOpacity>
          {/* Language */}
          <TouchableOpacity
            testID="languageSettingsBtn"
            style={[
              styles.linkCard,
              t.atoms.input_bg,
              pal.border,
              {borderTopRightRadius: 8, borderTopLeftRadius: 8},
              {borderBottomWidth: 1},
            ]}
            onPress={isSwitchingAccounts ? undefined : onPressLanguageSettings}
            accessibilityRole="button"
            accessibilityLabel={_(msg`Language settings`)}
            accessibilityHint={_(msg`Opens configurable language settings`)}>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <View style={[styles.iconContainer, t.atoms.bg_btn_default]}>
                <LanguageIcon />
              </View>
              <Text type="lg" style={pal.text}>
                <Trans>Languages</Trans>
              </Text>
            </View>
            <RightIcon />
          </TouchableOpacity>
          {/* About */}
          <TouchableOpacity
            style={[
              styles.linkCard,
              t.atoms.input_bg,
              a.pl_xl,
              a.pr_xl,
              {borderBottomRightRadius: 8, borderBottomLeftRadius: 8},
            ]}
            accessibilityRole="none"
            // onPress={onPressBuildInfo}
          >
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <View style={[styles.iconContainer, t.atoms.bg_btn_default]}>
                <InfoCircleIcon />
              </View>
              <Text type="lg" style={pal.text}>
                <Trans>About</Trans>
              </Text>
            </View>
            <Text
              type="sm"
              style={[styles.buildInfo, pal.textLight, t.atoms.text_sub]}>
              <Trans>
                Version {appVersion} {bundleInfo}
              </Trans>
            </Text>
          </TouchableOpacity>
          {/* Sign out */}
          <TouchableOpacity
            style={[
              styles.linkCard,
              t.atoms.input_bg,
              a.align_center,
              a.justify_center,
              a.mt_2xl,
              a.rounded_md,
            ]}
            onPress={
              isSwitchingAccounts ? undefined : onPressLogoutEveryAccount
            }
            accessibilityRole="button"
            accessibilityLabel={_(msg`Sign out of all accounts`)}
            accessibilityHint={undefined}>
            <Text style={[a.text_md, a.font_bold, t.atoms.text_danger]}>
              {accounts.filter(a => a.accessJwt && a.mnemonic).length > 1 ? (
                <Trans>Sign out of all accounts</Trans>
              ) : (
                <Trans>Sign out</Trans>
              )}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={styles.spacer20} />
        <View style={{height: 40}} />
      </ScrollView>
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  dimmed: {
    opacity: 0.5,
  },
  spacer20: {
    height: 20,
  },
  heading: {
    paddingHorizontal: 18,
    paddingBottom: 6,
  },
  infoLine: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 18,
    paddingBottom: 6,
  },
  profile: {
    flexDirection: 'row',
    marginVertical: 6,
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  linkCard: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
    height: 56,
    paddingHorizontal: 20,
    // marginBottom: 1,
  },
  linkCardNoIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 18,
    marginBottom: 1,
  },
  toggleCard: {
    paddingVertical: 8,
    paddingHorizontal: 6,
    marginBottom: 1,
  },
  avi: {
    marginRight: 12,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: colors.gray1,
    // marginRight: 12,
  },
  buildInfo: {
    paddingVertical: 8,
  },

  colorModeText: {
    marginLeft: 10,
    marginBottom: 6,
  },

  selectableBtns: {
    flexDirection: 'row',
  },

  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 32,
    padding: 14,
    backgroundColor: colors.gray1,
  },
  toggleBtn: {
    paddingHorizontal: 0,
  },
  footer: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 18,
  },
})
